#navmodal {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	row-gap: 5%;
	align-items: center;

	position: absolute;
	z-index: 2;
	width: 100%;
	height: 95%;

	animation: scaleIn 0.5s ease-out;

	background-color: color-mix(in srgb, var(--theme-lighter) 100%, #ffffff00 100%);
	backdrop-filter: blur(0.3em);

}

.slideIn {
	animation: slideIn 0.5s ease-out forwards;
}

.slideOut {
	animation: slideOut 0.5s ease-out forwards;
}

@keyframes slideIn {
	0% {
		transform: translate(0%, -150%);
	}
	100% {
		transform: translate(0%, 0%);
	}
}

@keyframes slideOut {
	0% {
		transform: translate(0%, 0%);
	}
	100% {
		transform: translate(0%, -150%);
	}
}


#navmodal > * {
	background-color: var(--theme-darker);
	font-size: 2em;
	padding: 0.25em;
}

#navmodal > svg {
	border-radius: 0;
	border-bottom-left-radius: 80%;
	border-bottom-right-radius: 80%;
	min-width: 1.5em;
	min-height: 1.5em;
}

#navmodal > nav {
	display: flex;
	min-height: 30%;
	width: 100%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: color-mix(in srgb, var(--theme-darker) 100%, #ffffff00 50%);

}

#navmodal > section {
	display: flex;
	min-height: 30%;
	width: 100%;
	flex-direction: column;
	justify-content: flex-start;
	row-gap: 5%;
	align-items: center;
	background-color: color-mix(in srgb, var(--theme-darker) 100%, #ffffff00 50%);

}
/* 
BREAKPOINT
Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
}

/* 
BREAKPOINT
Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* .headerNavlinks {
    display: flex;
    column-gap: 20%;
  } */
  /* .headerNavIcon {
    display: none !important; 
    align-self: flex-end;
  }

  .headerNav > span {
    display: none;
  } */
  
}

/* 
BREAKPOINT
Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
}

/* 
BREAKPOINT
Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}