#serverSelectionPage {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	row-gap: 1em;
}

#serverSelectionPage > h2 {
	padding: 1em 2em 0em 2em;
}

#serverSelectionPage > form {
	min-height: 30dvh;
	background-color: var(--theme);
	width: 75%;
	padding: 1em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}



form > div {
	display: flex;
	flex-direction: column;
	font-size: x-large;
	height: 10dvh;
	align-items: center;
	justify-content: center;
}

form > div > * {
	width: 15em;
	padding: 0.5em;
	font-size: large;
}

form > button {
	width: 15em;
	height: 3em;
	font-size: large;
	margin-top: 2em;
}


#serverDiscoverer {
	min-height: 30dvh;
	background-color: var(--theme);
	width: 75%;
	padding: 1em;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#serverDiscoverer > button {
	min-height: 3em;
	padding: 1em;
	font-size: large;
}

#serverDiscoverer > h5 {
	margin-top: 2em;
	margin-bottom: 0.5em;
	font-size: larger;
}

#serverDiscoverer > section {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	row-gap: 2em;
	width: 100%;

}

.serverDiscoveryInfoCard {
	background-color: var(--theme-dark);
	width: 100%;
	min-height: 5em;
	display: flex;
	flex-direction: column;
	padding: 1em;
	row-gap: 0.5em;
	align-items: center;
}

.serverDiscoveryInfoCard > h5 {
	font-size: x-large;
}

.serverDiscoveryInfoCard > h6 {
	font-size:medium;
}

.serverDiscoveryInfoCard > button {
	width: 10em;
	height: 3em;
	font-size: large;
	margin-top: 2em;
}


/* 
BREAKPOINT
Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
}

/* 
BREAKPOINT
Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	#serverDiscoverer > section {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		column-gap: 2em;
		width: 100%;
	
	}
	
	.serverDiscoveryInfoCard {
		width: 30%;
		

	}
  
}

/* 
BREAKPOINT
Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
}

/* 
BREAKPOINT
Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}