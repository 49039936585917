/* 

Use mobile-first CSS design!
This web client is re-used by various other platforms, 
so whatever is designed here will be used on more than just
the typical computer screen!

- Client-Web: browsers, any device
- Client-Android: wrapper for the PWA, could be phones, tablets, or other Android-based devices

*/


:root {
  --white: #FFFFFF;
  --black: #000000;

  --blue-lighter: #5F80B2;
  --blue-light: #3C629A;
  --blue: #224E90;
  --blue-medium: #224E90;
  --blue-dark: #143A73;
  --blue-darker: #072859;

  --purple-lighter: #7366B9;
  --purple-light: #5243A2;
  --purple: #392897;
  --purple-medium: #392897;
  --purple-dark: #281978;
  --purple-darker: #180B5D;

  --green-lighter: #86DC6B;
  --green-light: #62C642;
  --green: #43B91E;
  --green-medium: #43B91E;
  --green-dark: #2E930E;
  --green-darker: #1B7200;

  --red-lighter: #F47788;
  --red-light: #DE4A5E;
  --red: #D02139;
  --red-medium: #D02139;
  --red-dark: #A51024;
  --red-darker: #800012;

  --yellow-lighter: #FFCF7C;
  --yellow-light: #EAB04E;
  --yellow: #DA9723;
  --yellow-medium: #DA9723;
  --yellow-dark: #AE7411;
  --yellow-darker: #875500;

  --theme-lighter: var(--blue-lighter);
  --theme-light: var(--blue-light);
  --theme: var(--blue);
  --theme-medium: var(--blue);
  --theme-dark: var(--blue-dark);
  --theme-darker: var(--blue-darker);
}



/* 
BREAKPOINT

It's not a @media rule, but this is what to 
look for to jump to the other breakpoints.

Based on: https://www.w3schools.com/howto/howto_css_media_query_breakpoints.asp

Extra small devices (phones, 600px and down) */

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: var(--white);
  margin: 0;
  padding: 0;

  transition: 0.5s;
}

body {
  margin: 0;
  background-color: var(--theme);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("./assets/Sourcepool-Parchment-Background-500px.png"); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}


.row {
  width: 100%;
  background-color: var(--theme-dark);
}

header, footer {
  background-color: var(--theme-darker);
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* padding: 5%; */
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5dvh;
}

footer {
  height: 3dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5%;
  width: 100%;
}

header > span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  flex-grow: 1;
}

header > span > a {
  text-decoration: none;
  flex-grow: 0.1;
}
header > span > a > * {
  margin: 0;
  padding: 0;
  text-align: center;
}
header > span > a > h1 {
  font-size: 2dvh;
}

main, .App {
  background-color: var(--theme-dark);
  /* padding: 3%; */
  
  flex: 1;
}

main > div {
  padding: 3%;
}


#landingPage {
  text-align: center;
  height: 100%;
}


.headerNavlinks {
  display: none;
}
.headerNavIcon {
  align-self: flex-end;
}

.headerNav > span {
  width: 3em;
  height: 3em;
}

.headerNav > span > svg {
  height: 100%;
  width: 100%;
}



button, input {
  background-color: var(--theme-dark);
}




/* 
BREAKPOINT
Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
}

/* 
BREAKPOINT
Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* .headerNavlinks {
    display: flex;
    column-gap: 20%;
  } */
  /* .headerNavIcon {
    display: none !important; 
    align-self: flex-end;
  }

  .headerNav > span {
    display: none;
  } */
  
}

/* 
BREAKPOINT
Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
}

/* 
BREAKPOINT
Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}